var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "c-search-box",
        { on: { enter: _vm.getList } },
        [
          _c("template", { slot: "search" }, [
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-plant", {
                  attrs: { type: "search", name: "plantCd" },
                  model: {
                    value: _vm.searchParam.plantCd,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParam, "plantCd", $$v)
                    },
                    expression: "searchParam.plantCd",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-datepicker", {
                  attrs: {
                    range: true,
                    label: "LBL0010196",
                    defaultStart: "-6M",
                    defaultEnd: "6M",
                    name: "period",
                  },
                  model: {
                    value: _vm.period,
                    callback: function ($$v) {
                      _vm.period = $$v
                    },
                    expression: "period",
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c(
        "c-table",
        {
          ref: "table",
          attrs: {
            title: "LBL0010197",
            columns: _vm.grid.columns,
            gridHeight: _vm.grid.height,
            data: _vm.grid.data,
            rowKey: "proceedingsId",
            columnSetting: false,
            expandAll: true,
          },
          on: { linkClick: _vm.linkClick },
          scopedSlots: _vm._u([
            {
              key: "customArea",
              fn: function ({ props, col }) {
                return [
                  col.name === "click"
                    ? [
                        _vm.checkOpinion(props.row)
                          ? _c(
                              "q-chip",
                              {
                                attrs: {
                                  outline: "",
                                  square: "",
                                  color: "blue",
                                  clickable: true,
                                  "text-color": "white",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.rowClick(props.row)
                                  },
                                },
                              },
                              [_vm._v(" 클릭 ")]
                            )
                          : _vm._e(),
                      ]
                    : _vm._e(),
                ]
              },
            },
          ]),
        },
        [
          _c(
            "template",
            { slot: "table-button" },
            [
              _c(
                "q-btn-group",
                { attrs: { outline: "" } },
                [
                  _vm.editable
                    ? _c("c-btn", {
                        attrs: { label: "LBLREG", icon: "add" },
                        on: { btnClicked: _vm.bookPopup },
                      })
                    : _vm._e(),
                  _c("c-btn", {
                    attrs: { label: "LBLSEARCH", icon: "search" },
                    on: { btnClicked: _vm.getList },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }