<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 사업장 -->
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!--회의기간-->
          <c-datepicker
            :range="true"
            label="LBL0010196"
            defaultStart="-6M"
            defaultEnd="6M"
            name="period"
            v-model="period"
          />
        </div>
      </template>
    </c-search-box>
    <!--산업안전보건위원회 회의록 목록-->
    <c-table
      ref="table"
      title="LBL0010197"
      :columns="grid.columns"
      :gridHeight="grid.height"
      :data="grid.data"
      rowKey="proceedingsId"
      :columnSetting="false"
      :expandAll="true"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <!--등록-->
          <c-btn v-if="editable" label="LBLREG" icon="add" @btnClicked="bookPopup" />
          <!--검색-->
          <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
      <template v-slot:customArea="{ props, col }">
        <template v-if="col.name === 'click'">
          <q-chip
            v-if="checkOpinion(props.row)"
            outline square
            color="blue"
            :clickable="true"
            text-color="white"
            @click="rowClick(props.row)">
            클릭
          </q-chip>
        </template>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'proceedings',
  data() {
    return {
      grid: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            //사업장
            label: 'LBLPLANT',
            align: 'center',
            style: 'width:150px',
            sortable: true,
          },
          {
            name: 'proceedingsTitle',
            field: 'proceedingsTitle',
            //회의제목
            label: 'LBL0010198',
            align: 'left',
            type: "link",
            sortable: true,
          },
          {
            name: 'proceedingsDate',
            field: 'proceedingsDate',
            //회의일
            label: 'LBL0010199',
            align: 'center',
            style: 'width:200px',
            sortable: true,
          },
          {
            name: 'deptName',
            field: 'deptName',
            label: '작성부서',
            align: 'center',
            style: 'width:200px',
            sortable: true,
          },
          // {
          //   name: 'regName',
          //   field: 'regName',
          //   //작성자/작성일
          //   label: 'LBLREGUSERNDATE',
          //   align: 'center',
          //   style: 'width:140px',
          //   sortable: true,
          // },
          {
            name: 'proceedingStatusName',
            field: 'proceedingStatusName',
            label: '상태',
            align: 'center',
            sortable: true,
            style: 'width:160px',
          },
          {
            name: 'opinionDt',
            field: 'opinionDt',
            label: '의견접수마감일',
            align: 'center',
            sortable: true,
            style: 'width:120px',
          },
          {
            name: 'click',
            field: 'click',
            //회의제목
            label: '참석자의견작성',
            align: 'center',
            type: "custom",
            style: 'width:120px',
            sortable: true,
          },
          {
            // 결재진행단계
            name: 'approvalStatusName',
            field: 'approvalStatusName',
            label: '계획 결재',
            align: 'center',
            sortable: true,
            style: 'width:100px',
          },
          {
            // 결재진행단계
            name: 'approvalStatusName2',
            field: 'approvalStatusName2',
            label: '결과 결재',
            align: 'center',
            sortable: true,
            style: 'width:100px',
          },
        ],
        data: [],
      },
      searchParam: {
        plantCd: '',
        startYmd: '',
        endYmd: '',
      },
      period: [],
      editable: true,
      listUrl: '',
      popupOptions: {
        target: null,
        isFull: true,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.sai.osh.proceedings.list.url;
      // code setting
      this.searchParam.attendeeUserId = this.$store.getters.user.userId
      // list setting
      this.getList();
    },
    getList() {
      if (this.period) {
        this.searchParam.startYmd = this.period[0];
        this.searchParam.endYmd = this.period[1];
      } else {
        this.searchParam.startYmd = '';
        this.searchParam.endYmd = '';
      }
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    checkOpinion(row) {
      return row.proceedingStatusCd != 'PSC0000001' && row.proceedingStatusCd != 'PSC0000004' && this.$comm.getDatediff(this.$comm.moment(row.opinionDt), this.$comm.moment()) < 1 && row.attendeeUserIds.indexOf(this.$store.getters.user.userId) > -1
    }, 
    linkClick(row) {
      this.bookPopup(row);
    },
    bookPopup(result) {
      this.popupOptions.target = () => import(`${"./proceedingsDetail.vue"}`);
      this.popupOptions.title = 'LBL0010200';   //산업안전보건위원회 회의록 상세
      this.popupOptions.param = {
        proceedingsId: result ? result.proceedingsId : '',
        proceedingStatusCd: result ? result.proceedingStatusCd : 'PSC0000001',
      };
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
    rowClick(row) {
      this.popupOptions.target = () => import(`${"./proceedingOpinionReg.vue"}`);
      this.popupOptions.title = '회의 의견작성';   //산업안전보건위원회 회의록 상세
      this.popupOptions.param = {
        proceedingsId: row.proceedingsId,
      };
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
  }
};
</script>
